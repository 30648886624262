import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { PageProps } from 'gatsby';
import SEO from '../components/SEO';
import InternalBanner from '../components/InternalBanner';
import VignetteImg from '../images/CU-bann.jpg';
import SectionHeading from '../components/SectionHeading';
import InternalBody from '../components/InternalBody';
import {
  InternalBannerWrapper,
  InternalParagrapheSection,
  InternalSubTitle,
  InternalTitle,
} from '../components/Layout/styles';

import { messages as globalMessages } from '../components/messages';
import InternalLayout from '../components/Layout/InternalLayout';

function TermsAndConditions({ location }: PageProps) {
  const { t } = useTranslation();

  return (
    <>
      <SEO
        keywords={[
          'paiement vignette maroc',
          'ma vignette',
          'payer en ligne vignette maroc',
          'paiement vignette maroc en ligne',
          'payer vignette en ligne',
          'vignette en ligne',
          'ma vignette maroc',
          'paiement en ligne maroc',
          'paiement internet maroc',
          'impot voiture maroc',
          'taxe voiture maroc',
          'payer taxe voiture maroc',
          'payer dariba maroc',
          'payer dariba en ligne',
          'payer dariba',
          'vignette 2022',
          'payer vignette 2022',
          'payer vignette 2022 maroc',
        ]}
      />
      <InternalLayout path={location.pathname.replace(/\//g, '')}>
        <InternalBannerWrapper>
          <InternalBanner BannerBg={VignetteImg}>
            <SectionHeading
              title={t(
                globalMessages.navigationBar.termsAndConditionsLinkTitle(),
              )}
              subTitle="&nbsp;"
              align="center"
              withShadow
            />
          </InternalBanner>
          <InternalBody>
            <InternalParagrapheSection>
              <p>
                <strong> Société Générale</strong> Marocaine de Banques est une
                Société Anonyme à Directoire et Conseil de Surveillance au
                capital de 2 050 000 000 Dirhams, immatriculée au registre du
                commerce de Casablanca sous le numéro 28987 – Id Fis : 01084160
                - Patente : 36363350
              </p>
              <p>
                Son Siège Social est situé au 55, Boulevard Abdelmoumen, 20100
                Casablanca,
              </p>
              <p>
                Société Générale Marocaine de Banques, désignée ci-après comme «
                La Banque » édite et héberge le site web accessible à l’adresse
                vignette.sgmaroc.com appelé ci-après « le Site ».
              </p>
              <p>
                L’utilisateur reconnait expressément avoir obtenu de la part de
                la Banque, toutes les informations nécessaires quant aux
                services proposés et adhère sans réserve aux présentes
                conditions générales d’utilisation et de fonctionnement du Site.
              </p>
            </InternalParagrapheSection>
            <InternalTitle>ARTICLE 1 - OBJET DU SERVICE</InternalTitle>
            <InternalParagrapheSection>
              <p>
                Les présentes conditions générales ont pour objet de définir les
                modalités de fonctionnement et d'utilisation du site{' '}
                <a target="blank" href="https://vignette.sgmaroc.com">
                  vignette.sgmaroc.com
                </a>{' '}
                et les services proposés par la Banque qui sont mis à la
                disposition des utilisateurs du Site.
              </p>
              <p>
                Les informations contenues sur le Site vignette.sgmaroc.com ont
                un caractère strictement informatif, elles n'emportent aucun
                engagement juridique ni accord contractuel de la part de la
                Banque qui se réserve par ailleurs la faculté d'en modifier les
                caractéristiques à tout moment et sans préavis. Les utilisateurs
                du site sont donc invités à les consulter de manière régulière.
              </p>
              <p>
                Ce service est ouvert au grand public, clients et non clients de
                la banque.
              </p>

              <InternalSubTitle>Propriétés et Marques</InternalSubTitle>
              <p>
                <a target="blank" href="https://vignette.sgmaroc.com">
                  vignette.sgmaroc.com
                </a>{' '}
                est un domaine déposé par La Banque.
              </p>
            </InternalParagrapheSection>
            <InternalTitle>ARTICLE 2 – SERVICES DISPONIBLES</InternalTitle>
            <InternalParagrapheSection>
              <p>
                L’accès au Site{' '}
                <a target="blank" href="https://vignette.sgmaroc.com">
                  vignette.sgmaroc.com
                </a>{' '}
                se fait sans aucune restriction, il est destiné à tout porteur
                d’une carte bancaire. Ce site à pour objectif le paiement par
                carte bancaire de la Taxe Spéciale Annuelle sur les Véhicules
                Automobiles (vignette) 2018 et Taxe Spéciale Annuelle sur les
                Véhicules (vignettes relatives à 2019).
              </p>
              <p>
                Les services du Site sont évolutifs et sont appelés à être
                enrichis au fur et à mesure de la mise en service de nouvelles
                versions sur le Site.
              </p>

              <InternalSubTitle>Fonctionnement du service</InternalSubTitle>
              <p>
                Pour payer la vignette automobile à travers le Site, il vous
                sera demandé de saisir vos données personnelles et celles de
                votre véhicule et imprimer votre reçu de paiement.
              </p>
              <p>
                Dès validation du paiement, un email de confirmation vous sera
                envoyé contenant les informations du paiement enregistrées ainsi
                que le reçu en pièce jointe.
              </p>
              <p>
                Vous pourrez à tout moment, télécharger un duplicata de cette
                quittance sur le site de la{' '}
                <a target="blank" href="http://www.tax.gov.ma/">
                  http://www.tax.gov.ma/
                </a>
              </p>
              <p>
                Pour tout paiement validé en ligne sur le site{' '}
                <a target="blank" href="https://vignette.sgmaroc.com">
                  vignette.sgmaroc.com
                </a>
                , aucune annulation n’est autorisée.
              </p>
              <p>
                Les transactions de paiement en ligne par carte bancaire sont
                entièrement sécurisées. Le paiement est géré par la plateforme
                de Maroc Télécommerce, organisme certifié et reconnu par le
                Centre Monétique Interbancaire (CMI), par les Banques marocaines
                ainsi que par les organismes internationaux VISA et MasterCard.
                A aucun moment les informations relatives aux cartes bancaires
                ne transitent par le site{' '}
                <a target="blank" href="https://vignette.sgmaroc.com">
                  vignette.sgmaroc.com
                </a>
                .
              </p>
              <p>
                Vous attestez à la Banque que vous disposez des autorisations
                éventuellement nécessaires pour utiliser la carte bancaire dont
                les coordonnées sont saisies lors de la validation de votre
                paiement.
              </p>

              <InternalSubTitle>Tarification :</InternalSubTitle>
              <p>
                Le coût du service de paiement de la Vignette sur le site{' '}
                <a target="blank" href="https://vignette.sgmaroc.com">
                  vignette.sgmaroc.com
                </a>{' '}
                est de 12 DHS TTC hors frais de connexion internet applicables
                par les opérateurs et hors coût de la Taxe Spéciale Annuelle sur
                les Véhicules Automobiles (Vignette) 2021 et la Taxe Spéciale
                Annuelle sur les Véhicules (vignettes relatives à 2020).
              </p>
            </InternalParagrapheSection>
            <InternalTitle>ARTICLE 3 - ACCES AU SITE</InternalTitle>
            <InternalParagrapheSection>
              <InternalSubTitle>Accès au Site</InternalSubTitle>
              <p>
                L’utilisateur est informé que le Site est accessible en fonction
                de sa disponibilité, 24H/24 et 7J/7, sauf en cas de force
                majeure, de difficultés informatiques ou de difficultés
                techniques liées aux réseaux de télécommunications.
                <br /> L’accès au Site est direct, sans identifiant ni mot de
                passe.
              </p>
              <InternalSubTitle>Sécurité</InternalSubTitle>

              <p>
                La Banque prend les mesures physiques, techniques et
                organisationnelles appropriées pour assurer la sécurité et la
                confidentialité des données à caractère personnel, en vue
                notamment de les protéger contre toute perte, destruction
                accidentelle, altération et accès non autorisés.
              </p>
              <p>
                L’utilisateur accepte de prendre toutes les mesures appropriées
                de façon à protéger ses propres données et/ou logiciels de la
                contamination par des éventuels virus sur le réseau internet.
              </p>
              <p>
                La Banque ne saurait être responsable des atteintes à la
                sécurité informatique, pouvant causer des dommages aux matériels
                informatiques de l’utilisateur et à ses données.
              </p>
            </InternalParagrapheSection>
            <InternalTitle>ARTICLE 4 – ENGAGEMENTS DE LA BANQUE</InternalTitle>
            <InternalParagrapheSection>
              <p>
                La Banque s’engage à mettre à la disposition de l’utilisateur,
                l’information le concernant. Cela étant, et pour des raisons
                purement techniques, l’information fournie peut être obsolète au
                moment où elle est communiquée à l’utilisateur.
              </p>
              <p>
                En cas de survenance d’un problème technique quelconque, sur le
                Site, la Banque s’engage à remettre en service l’accès au Site
                dans un délai raisonnable. La Banque ne garantit pas la
                disponibilité systématique ou totale du Site et ne saurait être
                tenue responsable de tout dommage résultant d’une panne,
                interruption ou surcharge des systèmes de la Banque ou de l’un
                des tiers mandatés par ses soins.
              </p>
              <p>
                De même, la Banque se réserve le droit de suspendre l’accès au
                Site à sa propre initiative, pour effectuer, des évolutions et
                adaptations qui lui paraissent nécessaires et/ou de retirer un
                ou plusieurs produits et services sans être tenue d’en informer
                l’utilisateur.
              </p>
              <p>
                Les données collectées sont appelées à être utilisées
                principalement dans le cadre de gestion de la relation bancaire,
                la sélection des risques, la lutte contre le blanchiment des
                capitaux et le financement du terrorisme, la détermination du
                statut fiscal, la lutte contre la fraude, la prospection
                commerciale, la réalisation d’animations commerciales et de
                campagnes publicitaires ciblées.
              </p>
              <p>
                La Banque est susceptible de recueillir les données à caractère
                personnel (et notamment l'adresse IP du serveur de
                l’utilisateur), le cas échéant par le biais de formulaires.
                Certaines de ces données à caractère personnel sont obligatoires
                pour le traitement des demandes.
              </p>
              <p>
                Ces données indirectement nominatives, à usage statistique,
                peuvent également être collectées à des fins de gestion de votre
                connexion et de votre navigation.
              </p>
            </InternalParagrapheSection>
            <InternalTitle>ARTICLE 5 – OPPOSABILITE DU CONTRAT</InternalTitle>
            <InternalParagrapheSection>
              <p>
                La Banque se réserve le droit d’apporter aux présentes
                Conditions Générales toutes les modifications qu’elle juge
                nécessaires, et la possibilité d’en modifier en tout ou partie
                afin de l'adapter, notamment, aux évolutions de son
                exploitation, et/ou à l'évolution de la législation et/ou aux
                évolutions des services proposés.
              </p>
              <p>
                Les présentes Conditions Générales sont opposables pendant toute
                la durée d’utilisation du Site et jusqu’à ce que de nouvelles
                Conditions Générales d’utilisation remplacent les présentes.
              </p>
              <p>
                La Banque mettra à jour sur le Site, les nouvelles Conditions
                Générales d’utilisation et ce, dès leur entrée en vigueur.
              </p>
            </InternalParagrapheSection>
            <InternalTitle>
              ARTICLE 6 – RESPONSABILITE DE L’UTILISATEUR
            </InternalTitle>
            <InternalParagrapheSection>
              <p>
                L’utilisateur est pleinement responsable de son utilisation du
                site pour le paiement de sa vignette.
              </p>
              <p>Par ailleurs, l’utilisateur s’engage à :</p>
              <ul>
                <li>
                  ne pas avoir un comportement irresponsable et inadéquat
                  vis-à-vis de tous utilisateurs du Site (visiteurs et
                  administrateurs),
                </li>
                <li>
                  ne pas interférer ou interrompre le fonctionnement normal du
                  Site,
                </li>
                <li>ne pas utiliser ces contenus à des fins illégales,</li>
              </ul>
            </InternalParagrapheSection>
            <InternalTitle>ARTICLE 7 – PROPRIETE INTELLECTUELLE</InternalTitle>
            <InternalParagrapheSection>
              <p>
                L'ensemble de ce Site relève de la législation marocaine sur le
                droit d'auteur, le droit des marques et, de façon générale, sur
                la propriété intellectuelle, aussi bien en ce qui concerne sa
                forme (choix, plan, disposition des matières, moyens d'accès aux
                données, organisation des données...), qu'en ce qui concerne
                chacun des éléments de son contenu (textes, images...). Ces
                contenus, figurant sur les pages de ce Site, sont la propriété
                exclusive du groupe Société Générale et de ses contributeurs.
              </p>
              <p>
                Par exception, certains contenus (textes, images) sont la
                propriété intellectuelle de leurs auteurs respectifs.
              </p>
              <p>
                Toute reproduction, représentation, diffusion ou rediffusion, en
                tout ou partie, du contenu de ce Site sur quelque support ou par
                tout procédé que ce soit, de même que toute vente, revente,
                retransmission ou mise à disposition de tiers de quelque manière
                que ce soit sont interdites. Le non-respect de cette
                interdiction constitue une contrefaçon susceptible d'engager la
                responsabilité civile et pénale du contrefacteur.
              </p>
            </InternalParagrapheSection>
            <InternalTitle>ARTICLE 8 – LIENS HYPERTEXTES</InternalTitle>
            <InternalParagrapheSection>
              <p>
                Les liens hypertextes établis en direction d'autres Sites ne
                sauraient engager la responsabilité de la Banque, notamment
                s'agissant du contenu de ces Sites.
              </p>
              <p>
                La Banque n'est pas responsable des liens hypertextes pointant
                vers le présent Site et interdit à toute personne de mettre en
                place un tel lien sans son autorisation expresse et préalable.
              </p>
            </InternalParagrapheSection>
            <InternalTitle>
              ARTICLE 9 – SECRET PROFESSIONNEL ET DONNEES À CARACTERE PERSONNEL
            </InternalTitle>
            <InternalParagrapheSection>
              <p>
                La Banque est tenue au secret professionnel. L’obligation est
                faite à son personnel de ne pas révéler les informations
                confidentielles dont il a connaissance. Cependant, lorsque la
                loi le prévoit, le secret professionnel ne peut être opposé aux
                personnes, autorités ou organismes visés par cette dernière.
              </p>
              <p>
                En application des dispositions de la loi n° 09-08 relative à la
                protection des personnes physiques à l'égard du traitement des
                données à caractère personnel, et conformément à la délibération
                en vigueur de la Commission Nationale de Contrôle de la
                Protection des Données à Caractère Personnel (CNDP) relative à
                la tenue de compte de la clientèle et la gestion des opérations
                s’y rapportant, le Client donne consentement à la Banque à
                l’effet de traiter ses données personnelles pour la gestion de
                la tenue de comptes clientèle sous l’autorisation N°
                A-GC-190/2014. L’utilisateur consent en outre que ses données à
                caractère personnel soient communiquées à la société-mère de la
                Société Générale Marocaine des Banques, à ses filiales, à ses
                sous-traitants, aux autres établissements teneurs de comptes
                pour les transferts de fonds, aux intermédiaires pour
                l’exécution de certaines opérations bancaires, aux autorités
                compétentes ou de tutelle habilitées, aux centrales
                d’information, aux compagnies et courtiers d’assurances dûment
                habilités, aux ayants droit, tuteurs et mandataires habilités.
              </p>
              <p>
                La Banque est l’entité responsable du traitement des données
                personnelles.
              </p>
              <p>
                Société Générale Marocaine de Banques est une société anonyme,
                ayant son siège au 55, boulevard Abdelmoumen à Casablanca.
              </p>
              <p>
                Les données à caractère personnel collectées sur le site{' '}
                <a target="blank" href="https://vignette.sgmaroc.com">
                  vignette.sgmaroc.com
                </a>{' '}
                à l’aide des formulaires disponibles, font l’objet d’un
                traitement destiné pour l’initiation d’une ouverture d’un compte
                à distance et la souscription de produits.
              </p>
              <p>
                Les données à caractère personnel sont communiquées aux services
                du siège de la Société Générale Maroc, ainsi qu’a ses
                sous-traitants.
              </p>
              <p>
                Conformément à la loi 09-08 promulguée par le dahir 1-09-15 du
                18 février 2009, relative à la protection des personnes
                physiques à l’égard du traitement des données à caractère
                personnel, vous bénéficiez d’un droit d’accès et de
                rectification aux informations qui vous concernent, que vous
                pouvez exercer en vous adressant par courrier à :
              </p>
              <ul>
                <li>Société Générale Marocaine de Banques</li>
                <li>Conformité</li>
                <li>55, boulevard Abdelmoumen, Casablanca</li>
                <li>Tel : 0522 42 42 43</li>
              </ul>
              <p>
                Vous pouvez également, pour des motifs légitimes, vous opposer à
                ce que les données qui vous concernent fassent l’objet d’un
                traitement.
              </p>
              <p>
                Vos données personnelles restent strictement confidentielles et
                protégées. Nous avons mis en place des systèmes et des
                procédures professionnels adéquats. De plus, nous utilisons des
                restrictions techniques et physiques pour limiter l’accès à vos
                données personnelles et leurs utilisations. Seul le personnel
                habilité est autorisé à accéder à vos informations personnelles
                pour exercer ses fonctions dans le cadre de nos services.
              </p>
            </InternalParagrapheSection>
            <InternalTitle>ARTICLE 10 – CONVENTION DE PREUVE</InternalTitle>
            <InternalParagrapheSection>
              <p>
                L’acceptation par l’utilisateur des présentes conditions
                générales par voie électronique à la même valeur probante que
                l’accord sur support papier. Les registres informatisés et
                conservés dans les systèmes informatiques sont conservés dans
                des conditions raisonnables de sécurité et considérés comme les
                preuves des communications intervenues entre les parties.
              </p>
            </InternalParagrapheSection>
            <InternalTitle>
              ARTICLE 11 – LITIGES ET ATTRIBUTION DE JURIDICTION
            </InternalTitle>
            <InternalParagrapheSection>
              <p>
                Tout litige qui naîtrait à l’occasion de l’exécution ou de
                l’interprétation des présentes conditions générales sera résolu
                à l’amiable. A défaut de résolution à l’amiable dans un délai de
                trente jours à compter de la notification qui en sera faite par
                la partie la plus diligente, la compétence juridictionnelle est
                attribuée aux Tribunaux de Casablanca compétents en la matière.
              </p>
            </InternalParagrapheSection>
          </InternalBody>
        </InternalBannerWrapper>
      </InternalLayout>
    </>
  );
}

export default memo(TermsAndConditions);
